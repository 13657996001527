/*
    ---------------------------MIXIN REFERNCES---------------------------

    1) https://engageinteractive.co.uk/blog/top-10-scss-mixins

*/

// Make your content center align, don't worry about top and bottom margin
@mixin push--auto {
  margin: {
    left: auto;
    right: auto;
  }
}

//pseudo ::after ::before
@mixin pseudo($display: block, $pos: absolute, $content: "") {
  content: $content;
  display: $display;
  position: $pos;
}

//triangles
@mixin css-triangle($color, $direction, $size: 6px, $position: absolute, $round: false) {
  @include pseudo($pos: $position);
  width: 0;
  height: 0;
  @if $round {
    border-radius: 3px;
  }
  @if $direction == down {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: $size solid $color;
    margin-top: 0 - calc($size / 2.5);
  } @else if $direction == up {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $color;
    margin-bottom: 0 - calc($size / 2.5);
  } @else if $direction == right {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $color;
    margin-right: -$size;
  } @else if $direction == left {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-right: $size solid $color;
    margin-left: -$size;
  }
}

//for default font
@mixin default-header-font($size: false, $colour: false, $weight: false, $lh: false) {
  font-family: $primary-header-font, $secondary-font;
  @if $size {
    font-size: $size;
  }
  @if $colour {
    color: $colour;
  }
  @if $weight {
    font-weight: $weight;
  }
  @if $lh {
    line-height: $lh;
  }
}

@mixin default-body-font($size: false, $colour: false, $weight: false, $lh: false) {
  font-family: $primary-body-font, $secondary-font;
  @if $size {
    font-size: $size;
  }
  @if $colour {
    color: $colour;
  }
  @if $weight {
    font-weight: $weight;
  }
  @if $lh {
    line-height: $lh;
  }
}
