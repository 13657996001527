.overlap-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 100%;
  max-width: 100%;
  align-self: center;
  height: -webkit-fill-available;
  overflow: auto;
  flex-wrap: wrap;
}

.image-background {
  min-height: 36rem;
  position: relative;
  background-repeat: no-repeat;
  background-position: top right;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: 100% 100%;
}

.sign-in-image {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  background-color: rgba(88, 96, 110, 0.6);
  width: 100%;
  height: 100%;
}
