.avatar-jumbo {
  width: 52px;
  height: 40px;
  display: inline-flex;
  vertical-align: top;
  align-items: center;
  margin: 0;
  border-radius: 50%;
}

.avatar-icon {
  width: 52px;
  height: 52px;
  font-size: 1.125rem;
  color: #ffffff;
  background-color: #3272d9;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.avatar-icon i {
  font-size: 1.5rem;
}

.announcement-icon {
  flex: 0 0 3.25rem;
}

.announcements-title-padding {
  padding: 0.75rem 1rem;
}

.responsive-card {
  width: 38rem;

  @media (max-width: 540px) {
    width: 18rem;
  }

  @media (max-width: 720px) {
    width: 20rem;
  }
}
