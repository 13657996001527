.brdcm-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;

  @include push--auto;

  @include media_query("phone") {
    max-width: 540px;
  }

  @include media_query("tablet-portrait") {
    max-width: 720px;
  }

  @include media_query("tablet-landscape") {
    max-width: 960px;
  }

  @include media_query("desktop") {
    max-width: 1170px;
  }

  @include media_query("desktop-wide") {
    max-width: 1400px;
    padding: 0;
  }

  @include media_query("desktop-xl") {
    max-width: 1900px;
    padding: 0;
  }
}
