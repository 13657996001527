/*
    ---------------------------TYPOGRAPHY REFERNCES---------------------------

    1) https://medium.com/codyhouse/create-your-design-system-part-1-typography-7c630d9092bd

*/

$primary-header-font: "Gotham Medium";
$primary-body-font: "Gotham-Book";
$secondary-font: sans-serif;

/*  set  base values  */
$text-size: 16px;
$text-base-size: 1rem;
$text-scale-ratio: 0.75rem;

/*  size set  */
$text-sm: 0.75rem; //12px
$text-md: 0.875rem; //14px
$text-lg: 1.125rem; //18px
$text-xlg: 1.5rem;
$text-xl: 1.75rem; //28px
$text-xxl: 2.25rem; //36px

/* spacing values */
$space-xxxs: 0.25em;
$space-xxs: 0.375em;
$space-xs: 0.5em;
$space-sm: 0.75em;
$space-md: 1.25em;
$space-lg: 2em;
$space-xl: 3.25em;
$space-xxl: 5.25em;
$space-xxxl: 8.5em;
