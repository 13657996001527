.cross-icon {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: sticky;
  top: 0;
  height: 2.5rem;
  background: white;
  z-index: 1900;
  border: none;
}

.p-header {
  background: grey;
  color: black;
  border: solid #dee2e6;
  border-width: 1px 0 0 0;
  padding: 2rem;
  font-weight: 600;
  margin: 8rem;
}

.drawer {
  width: 30rem;
  overflow: auto;
  z-index: 2202;
  @media (max-width: 540px) {
    width: 15rem;
  }

  @media (max-width: 720px) {
    width: 20rem;
  }
}

.p-sidebar {
  background: #ffffff;
  color: #212529;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: none;
  position: fixed;
  transition: transform 0.3s;
  display: flex;
  flex-direction: column;
}

.p-sidebar-right {
  top: 0;
  right: 0;
  width: 20rem;
  height: 100%;
}

.preview-table {
  width: 100% !important;
  padding: 0.7rem !important;
  overflow-y: auto;
  @media (max-width: 540px) {
    width: 15rem;
  }
}

.preview-table > .table-responsive {
  border: 1px solid #ccc;
  border-radius: 8px;
}
