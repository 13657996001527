.mt3 {
  @media (max-width: 920px) {
    margin-top: 1rem !important;
  }
}

@media (min-width: 992px) {
  .modal-lg, .modal-xl {
      max-width: none !important;
  }
}

.modal-feedback {
  vertical-align: middle;
  margin-top: 10px;
  border: 1px solid #ccc;
  height: "auto";
}

.serial-label {
  width: 11rem;
  font-weight: bold;
}

.serial-container {
  display: flex;
  align-items: baseline;
}

.feedback-width {
  margin-top: 10px;
  margin-bottom: 10px;
}

.serial-table {
  display: block;
}

.serial-table-body {
  display: block;
  max-height: 400px;
  overflow-y: scroll;
}

.pl-78 {
  padding-left: 78px;
}

.users-table {
  line-height: 40px;
}

.overflow-users {
  max-height: 60rem;
  overflow: hidden auto;
}

select.form-control:disabled {
  background-image: none;
}

.date-picker-sites {
  width: 10rem;
  height: 2.5rem;
}

.react-date-picker__wrapper {
  border: 0.1rem solid rgb(206, 212, 218);
  border-radius: 4px;
}

.react-date-picker__calendar{
  z-index: 10;
}

.site-detail-filter-height{
  top:2.75rem !important
}