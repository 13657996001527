.accordion-border {
  border: 1px solid #ccc;
}

.accordion-summary {
  flex-direction: row-reverse;
  background-color: rgba(0, 0, 0, 0.04) !important;
}
.accordion-repository-summary {
  flex-direction: row-reverse;
  background-color: #cce5ff !important;
}
.accordion-summary > .MuiAccordionSummary-expandIconWrapper.Mui-expanded,
.accordion-repository-summary > .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
  transform: rotate(90deg);
}

.accordion-background-blue {
  background-color: skyblue;
}

.accordion-typography {
  font-size: 0.9rem;
  font-weight: bold;
}

.accordion-details {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  padding: 1.5rem !important;
}

.MuiAccordionSummary-content {
  margin-left: 8px !important;
}
