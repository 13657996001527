.card {
  border: none;
  box-shadow: 0px 4px 16px rgba(51, 56, 64, 0.08);
  border-radius: 8px;
  .card-body {
    .card-desc {
      font-size: 14px;
      line-height: 21px;
      color: #4f575e;
    }
    .card-header-txt {
      font-size: 16px;
      line-height: 22px;
      color: #272b30;
    }

    .card-footer-txt {
      font-size: 14px;
      line-height: 18px;
      color: #a7a8aa;
    }
  }
}
