/*  order is important  */

@import "./vars.scss";
@import "./fonts";
@import "./colors";
@import "./typography";
@import "./_mixins";
@import "./media_query";
@import "./visibility";
@import "./z-index";
@import "./theme_default.scss";

// ============================== GLOBALS ====================================
@import "./common/generic";
@import "./common/container";
@import "./common/modal-dropdown.scss";
@import "./common/button";
@import "./common/forminputs";
@import "./common//skeleton.scss";

body {
  @include default-body-font($text-size, $primary-font-color, 400, 1.5);
  overflow-x: hidden;
}
h1,
.h1 {
  @include default-header-font($text-xl, $primary-header-color, 500, 2.18);
}

h2,
.h2 {
  @include default-header-font($text-lg, $secondary-header-color, 500, 1.35);
}

h3,
.h3 {
  @include default-header-font($text-base-size, $secondary-header-color, 500, 1.5);
}

h4,
.h4 {
  @include default-header-font($text-md, $secondary-header-color, 500, 1.3);
}

.title {
  @include default-body-font($text-base-size, $secondary-font-color, 400, 1);
}

.text-small {
  @include default-body-font($text-sm, $secondary-font-color, 400, 1.5);
}

.ecx-container {
  @include default-body-font($text-md, $primary-font-color, 400, 1.5);
  background-color: $body-bg-color;
}

.default-nav-link {
  color: $link-default-color !important;
}

.pe-cursor {
  cursor: pointer;
}

.ecx-rowx-n {
  margin-left: -1rem;
  margin-right: -1rem;
}

.text-size-sm {
  font-size: $text-sm !important;
}

.text-size-md {
  font-size: $text-md !important;
}

.text-size-lg {
  font-size: $text-lg !important;
}

.text-size-xl {
  font-size: $text-xl !important;
}

.text-size-xxl {
  font-size: $text-xxl !important;
}
.word-breal-all {
  word-break: break-all;
}

.text-red {
  color: $palette-red-100;
}

.link-on-hover {
  &:hover {
    color: $link-default-color;
  }
}

.ecx-overflow-anywhere {
  overflow-wrap: anywhere;
}
.tooltip {
  z-index: $tooltip-z-index;
}

.form-feedback-item {
  font-size: $text-sm !important;
}
.panel-secondary {
  .panel-collapse.show {
    height: auto !important;
  }
}

.dashboard-top-tile-height {
  height: 280px;

  @include media_query("desktop") {
    height: 220px;
  }
}

.ecx-portlet-width {
  max-width: 100%;

  @include media_query("desktop-xl") {
    max-width: 1900px;
    margin-left: auto;
    margin-right: auto;
    width: 1900px;
  }
}

.ecx-portal-section {
  .h1 {
    @include media_query("tablet-portrait") {
      font-size: $text-lg;
      line-height: 1.18;
    }
    @include media_query("tablet-landscape") {
      font-size: $text-xl;
      line-height: 1.33;
    }
  }
  .h2 {
    @include media_query("tablet-portrait") {
      font-size: $text-md;
      line-height: 1.2;
      padding-bottom: 0.75rem !important;
    }
    @include media_query("tablet-landscape") {
      font-size: $text-lg;

      line-height: 1.2;
    }
  }
}

.ecx-support-section {
  .text-section-width {
    @include media_query("tablet-portrait") {
      max-width: 83.33%;
    }
  }

  a {
    @include media_query("tablet-portrait") {
      padding: 0.5rem 0.5rem;
    }
  }
}

.z-indexed-1200 {
  z-index: 1200 !important;
}
