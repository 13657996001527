.error > .MuiAlert-root {
  background: #f8d7da;
  border: solid #f5c6cb;
  border-width: 1px;
  color: #721c24;
}

.error-color {
  color: #721c24;
}

.success > .MuiAlert-root {
  background: #d4edda;
  border: solid #c3e6cb;
  border-width: 1px;
  color: #155724;
}

.success-color {
  color: #155724;
}
