@import "./../../../styles/brand/property_definitions/colors";
.navBar {
  border-top: 0.5em solid $palette-red-0;
  .navBarBorder {
    background-color: black;
    height: 3rem;
  }

  .nav-link {
    @media (max-width: 1280px) {
      padding-left: 0.7rem;
      padding-right: 0.7rem;
    }
    &.active {
      color: white;
      font-weight: bold;
    }
    font-size: 1rem;
  }

  .nav-text {
    text-decoration: none;
    color: grey;
  }

  .nav-text:hover {
    color: white;
  }
}
