.brdcm-phone-visible {
  display: block;

  @include media_query("phone") {
    display: none !important;
  }
}

.brdcm-tablet-portrait-visible {
  display: block;

  @include media_query("tablet-portrait") {
    display: none !important;
  }
}

.brdcm-tablet-visible {
  display: block;

  @include media_query("tablet-landscape") {
    display: none !important;
  }
}

.brdcm-desktop-visible {
  display: block;

  @include media_query("desktop") {
    display: none !important;
  }
}

.brdcm-desktop-wide-visible {
  display: block;

  @include media_query("desktop-wide") {
    display: none !important;
  }
}

.brdcm-phone-hidden {
  display: block;

  @include media_query("phone", max) {
    display: none !important;
  }
}

.brdcm-tablet-portrait-hidden {
  display: block;

  @include media_query("tablet-portrait", max) {
    display: none !important;
  }
}

.brdcm-tablet-hidden {
  display: block;

  @include media_query("tablet-landscape", max) {
    display: none !important;
  }
}

.brdcm-desktop-hidden {
  display: block;

  @include media_query("desktop", max) {
    display: none !important;
  }
}

.brdcm-desktop-wide-hidden {
  display: block;

  @include media_query("desktop-wide", max) {
    display: none !important;
  }
}
