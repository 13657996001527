// @import '../property_definitions/common.scss';

.scrt-theme-table {
  &.scrt-empty-table {
    height: 100%;
  }

  .table-responsive {
    overflow-y:hidden !important ;
    height: 100%;
    .scrt-table {
      thead {
        tr {
          .table-list-title {
            border: none !important;
            font-size: 12px !important;
            font-weight: bold !important;

            .scrt-table-header {
              word-break: break-all;
              white-space: break-spaces;
            }
          }
        }
      }

      tbody {
        .scrt-table-row {
          .scrt-table-cell {
            word-break: break-all;
            white-space: break-spaces;
          }
        }
        .scrt-table-row-selectable {
          cursor: pointer;
        }
      }
    }
  }
}

.scrt-table {
  display: block;
  max-height: 450px;
  overflow: auto !important;
  thead {
    //display: block !important;
    tr {
      th{
        position: sticky!important;
        top:0 !important;
        z-index: 10 !important;
      }
      td{
        position: sticky!important;
        top: 3.5rem;
        z-index: 10 !important;
      }
      .scrt-table-header {
        white-space: break-spaces;
        font-family: $primary-header-font;
      }
      .scrt-table-select-all {
        width: 80px;
        padding: 0.3rem !important;
        &:hover {
          background-color: #005c8a;
          color: white;
          opacity: 1;
        }
      }
    }
  }

  tbody {
    // display: block !important;
    // max-height: 300px !important;
    // overflow-y: scroll !important;
    .scrt-table-row {
      .scrt-table-cell {
        white-space: break-spaces;
        .scrt-checkbox-center {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .scrt-table-row-selectable {
      cursor: pointer;
      height: 50px;
    }
  }
}

.date-picker-input {
  padding: 8px;
  font-size: 13px;
}

.date-picker-footer {
  display: flex;
  justify-content: space-between;
}

.table td {
  padding: 0.2rem 0.5rem;
  word-wrap: break-word;
}

.date-footer-btn {
  line-height: 0.7 !important;
  padding: 0.5rem !important;
}
