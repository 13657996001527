.content {
  text-align: center;
  margin-top: 10rem;
  font-size: 1.5rem;
}

.notes {
  text-align: start;
  padding-left: 10rem;
  padding-top: 1rem;
}
