.mt3 {
  @media (max-width: 920px) {
    margin-top: 1rem !important;
  }
}

.quill {
  border: 1px solid #e7e7ed;
  border-radius: 0.25rem;
}

.ql-toolbar {
  background: #efefef;
  margin-bottom: 0;
}

.ql-editor {
  min-height: 10rem;
  padding: 1rem;
  font-size: 16px;
}
