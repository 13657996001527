/*--------------------------------------------------------------
# Typography
--------------------------------------------------------------*/
/* Gotham-book */
@font-face {
  font-family: "Gotham Book";
  font-style: normal;
  font-weight: normal;
  src: url("fonts/GothamBook.eot");
  src: local("☺"), url("fonts/GothamBook.woff") format("woff"),
    url("fonts/GothamBook.ttf") format("truetype"), url("fonts/GothamBook.svg") format("svg");
}

/* Gotham-medium */
@font-face {
  font-family: "Gotham Medium";
  font-style: normal;
  font-weight: normal;
  src: url("fonts/GothamMedium.eot");
  src: local("☺"), url("fonts/GothamMedium.woff") format("woff"),
    url("fonts/GothamMedium.ttf") format("truetype"), url("fonts/GothamMedium.svg") format("svg");
}

/* Gotham-thin */
@font-face {
  font-family: "Gotham Thin";
  font-style: normal;
  font-weight: normal;
  src: url("fonts/GothamThin.eot");
  src: local("☺"), url("fonts/GothamThin.woff") format("woff"),
    url("fonts/GothamThin.ttf") format("truetype"), url("fonts/GothamThin.svg") format("svg");
}

@font-face {
  font-family: "Gotham hco";
  font-style: normal;
  font-weight: 400;
  src: url("fonts/GothamBook.eot");
  src: local("☺"), url("fonts/Gotham-Book_Web.woff2") format("woff2"),
    url("fonts/GothamBook.ttf") format("truetype"), url("fonts/GothamBook.svg") format("svg");
}

@font-face {
  font-family: "Gotham hco";
  font-style: normal;
  font-weight: 500;
  src: url("fonts/GothamBook.eot");
  src: local("☺"), url("fonts/Gotham-Medium_Web.woff2") format("woff2"),
    url("fonts/GothamBook.ttf") format("truetype"), url("fonts/GothamBook.svg") format("svg");
}

@font-face {
  font-family: "arial w01 regular903292";
  font-style: normal;
  src: url("fonts/GothamBook.eot");
  src: local("☺"), url("fonts/a3dac99c-9132-4c77-bc18-2df40293d361.woff2") format("woff2"),
    url("fonts/GothamBook.ttf") format("truetype"), url("fonts/GothamBook.svg") format("svg");
}
