@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.brdcm-fade-in {
  animation: fadeIn 0.5s;
}

//@mixin css-triangle($color, $direction, $size: 6px, $position: absolute, $round: false){
.brdcm-down-chevron {
  @include css-triangle(#fff, down);
}
