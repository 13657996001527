.clay-button-icon {
  width: 2.5rem;
  height: 2.5rem;
  margin: 0.2rem;
  font-size: 1.2rem !important;
}

.scrt-repo-action-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  padding: 8px !important;
}
