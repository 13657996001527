/*
    ---------------------------TYPOGRAPHY REFERNCES---------------------------

    1) https://medium.com/codyhouse/create-your-design-system-part-1-typography-7c630d9092bd

*/

$primary-font: "Gotham hco";
$secondary-font: sans-serif;

/*  set  base values  */
$text-size: 16px;
$text-base-size: 1em;
$text-scale-ratio: 1.2em;

/*  size set  */
$text-xs: calc(1em / (var($text-scale-ratio) * var($text-scale-ratio))); //9.72px
$text-sm: calc(1em / var($text-scale-ratio));
$text-md: calc(1em * var($text-scale-ratio));
$text-lg: calc(1em * var($text-scale-ratio) * var($text-scale-ratio));
$text-xl: calc(1em * var($text-scale-ratio) * var($text-scale-ratio) * var($text-scale-ratio));
$text-xxl: calc(
  1em * var($text-scale-ratio) * var($text-scale-ratio) * var($text-scale-ratio) *
    var($text-scale-ratio)
);
$text-xxxl: calc(
  1em * var($text-scale-ratio) * var($text-scale-ratio) * var($text-scale-ratio) *
    var($text-scale-ratio) * var($text-scale-ratio)
);

/* spacing values */
$space-xxxs: 0.25em;
$space-xxs: 0.375em;
$space-xs: 0.5em;
$space-sm: 0.75em;
$space-md: 1.25em;
$space-lg: 2em;
$space-xl: 3.25em;
$space-xxl: 5.25em;
$space-xxxl: 8.5em;
