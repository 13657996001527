.overview {
  width: auto;
  height: 60%;
}

.overview-container {
  width: 100%;
  height: 100%;
}

.overview-table {
  line-height: 40px;
}

.active-serial-number {
  max-width: 150px;
  word-wrap: break-word;
  line-height: 25px;
}

.table-container tbody {
  display: block;
  max-height: 300px;
  overflow-y: auto;
}

.table-container thead,
.table-container tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.overview-label {
  font-size: 1.2rem;
}

.recharts-cartesian-axis-tick-value {
  font-size: 14px;
}
