.repository-logs {
  padding: 0.5rem 0.5rem;
  background: #dcdcdc;
  color: #212529;
  border: solid #dee2e6;
  border-width: 1px 0 0 0;
  font-weight: 600;
}

.audit-log-table-filter{
  top:4rem !important
}

.repo-table-filter-height{
  top:2.75rem !important
}