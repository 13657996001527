//shades of red
//$palette-red-0:     rgba(252, 234, 234, 1);
$palette-red-0: rgba(206, 9, 48, 1);
$palette-red-100: rgba(220, 32, 32, 1);
$palette-red-200: rgba(204, 9, 47, 1);
$palette-red-300: rgba(255, 0, 40, 0.1);

//shades of grey
$palette-grey-0: rgb(248, 249, 250);
$palette-grey-1: rgba(248, 249, 250, 1);
$palette-grey-100: rgba(233, 236, 239, 1);
$palette-grey-200: rgba(222, 226, 230, 1);
$palette-grey-300: rgba(206, 212, 218, 1);
$palette-grey-400: rgba(173, 181, 189, 1);
$palette-grey-500: rgba(106, 113, 120, 1);
$palette-grey-600: rgba(79, 87, 94, 1);
$palette-grey-700: rgba(39, 43, 48, 1);
$palette-grey-800: rgba(16, 18, 19, 1);

//shades of black
$palette-black-0: rgba(0, 0, 0, 1);

//shades of white
$palette-white-0: rgba(255, 255, 255, 1);
$palette-white-400: rgb(238, 238, 238);

//shades of blue
$palette-blue-0: rgba(0, 92, 138, 1);
$palette-blue-100: rgb(229, 245, 252);
$palette-blue-200: rgb(229, 240, 254);
$palette-teal-0: rgb(0, 131, 160);

//shades of green
$palette-green-0: rgba(241, 248, 232, 1);
$palette-green-100: rgba(116, 184, 22, 1);
$palette-green-200: rgba(41, 130, 59, 1);

//shades of orange
$palette-orange-0: rgba(253, 246, 229, 1);
$palette-orange-100: rgb(235, 160, 0);
