//shades of red
$palette-red-0: rgb(204, 9, 47);

//shades of grey
$palette-grey-0: rgb(174, 175, 175);
$palette-grey-100: rgb(69, 69, 69);
$palette-grey-200: rgb(78, 85, 91);
$palette-grey-300: rgb(84, 91, 98);
$palette-grey-600: rgb(51, 51, 51);
$palette-grey-700: rgb(83, 86, 90);

//shades of white
$palette-white-0: rgb(255, 255, 255);
$palette-white-400: rgb(238, 238, 238);

//shades of blue
$palette-teal-0: rgb(0, 131, 160);
$palette-blue-0: rgba(0, 92, 138, 1);

//social color
$palette-fb: rgb(59, 89, 152);
$palette-twitter: rgb(29, 161, 242);
$palette-linkedin: rgb(0, 119, 181);
$palette-youtube: rgb(205, 32, 31);
