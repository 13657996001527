.toggle-switch-check-bar{
    .toggle-switch-check:empty ~ .toggle-switch-bar{
        &:before{
            background-color: $palette-grey-300;
            border-color: $palette-grey-300;
            border-radius: 20px;
            border-style: solid;
            border-width: 1px;
            // bottom: 0;
            // content: ' ';
            display: block;
            //left: 0;
            position: absolute;
            //top: 0;
            transition: background-color 100ms ease-in,border-color 100ms ease-in,box-shadow 150ms ease-in-out,color 100ms ease-in,left 100ms ease-in,right 100ms ease-in;
            //width: 39px;
        }
       &:after{
            background-color: $palette-white-0;
            border-color: $palette-white-0;
            border-radius: 50%;
            border-style: solid;
            border-width: 1px;
            //bottom: 3px;
            content: '';
            display: block;
            // height: 19px;
            // left: 3px;
            position: absolute;
            //top: 3px;
            transition: background-color 100ms ease-in,border-color 100ms ease-in,box-shadow 150ms ease-in-out,color 100ms ease-in,left 100ms ease-in,right 100ms ease-in;
            //width: 18px;
       }
    }

    .toggle-switch-check:focus ~ .toggle-switch-bar:before{
        box-shadow: none;
    }

    .toggle-switch-check:checked ~ .toggle-switch-bar{
        &:before{
            background-color: $palette-green-100;
            border-color: $palette-green-100;
            border-radius: 20px;
            border-style: solid;
            border-width: 1px;
        }
        &:after{
            background-color: $palette-white-0;
            border-color: $palette-white-0;
            border-radius: 50%;
            border-style: solid;
            border-width: 1px;
            //left: 19px;
        }
    }
}
