.repo-log-table {
  line-height: 40px;
}

.signIn-signOut-table-filter{
  top: 2.75rem !important
}

.repo-log-table-filter{
  top: 4.25rem !important
}

.audit-log-table-filter{
  top: 3.75rem !important
}

.rem-log-table-filter{
  top:3.25rem !important
}

.console-rem-log-table-filter{
  top:4rem !important
}

  // .scrt-table thead{
  //   display: block;
  // }



  // .scrt-table tbody{
  // display: block;
  // max-height: 300px;
  // overflow-y: scroll;
  // }

// .scrt-table-date{
//   width: 10rem;
// }
