.help-table {
  table {
    width: 100%;
  }
  table,
  th,
  td {
    border: 1px solid black;
    border-collapse: collapse;
  }
}
