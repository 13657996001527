.support-link {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.header-border {
  border-bottom: 0.5em solid rgb(204, 9, 47);
}
